import * as React from 'react'
import { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { useMedia } from 'react-use'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import Image from '../components/partials/image'
import HueCard from '../components/partials/hue'
import Emoji from '../components/partials/emoji'

import Slideshow from '../components/about/slideshow'

const AboutPage = ({ data }) => {
	const about = data.strapi.aboutPage
	const drafts = data.strapi.teams

	const [position, setPosition] = useState({ x: 0, y: 0 })

	const moveImage = event => {
		const X = event.clientX / 10
		const Y = event.clientY / 10
		setPosition({ x: X, y: Y })
	}

	const { scrollYProgress } = useViewportScroll()

	const textDesktop = useTransform(scrollYProgress, [0.115, 0.175], [-168, 168])
	const imageDesktop = useTransform(scrollYProgress, [0.55, 0.675], [-200, 0])
	const isDesktop = useMedia(`(min-width: 1200px)`)

	return (
		<Layout page={about} metadata={about.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1 className="w-11/12 md:w-10/12">{about.headline}</h1>
				</section>

				{/* Hero Unit */}
				<section className="py-20 lg:py-40 xl:py-80">
					<Slideshow slides={about.slideshow} speed={1000} />
				</section>

				{/* Text Scroll Animation */}
				<section className="py-40 lg:py-80">
					<div className="block xl:py-200">
						<motion.div
							className="mx-auto sm:w-9/12 md:w-9/12 lg:w-full xl:w-10/12 xxl:w-8/12"
							style={{
								translateY: isDesktop ? textDesktop : 'none',
							}}
						>
							<h3 className="leading-loose text-center px-5 lg:px-40">
								Opposite is a design studio that works in three areas: Brand
								Identity Design
								<Emoji
									label="Brand Identity Design"
									emoji="&#x1F3F4;&#x200D;&#x2620;&#xFE0F;"
									space="10"
								/>
								Packaging Design
								<Emoji label="Packaging Design" emoji="&#x1F9F4;" space="10" />
								and User Experience Design
								<Emoji label="UX Design" emoji="&#x1F4F1;" space="10" />
								We take businesses from zero to one
								<Emoji label="Rocket" emoji="&#x1F680;" space="10" />
								More than anything else, Opposite is a stance. A set of
								deeply-held beliefs about building brands and operating a design
								practice. We bring these beliefs to every single project we work
								on.
							</h3>
						</motion.div>
					</div>
				</section>

				{/* Zig-Zag Sections */}
				<section className="py-80 xl:py-120">
					{/* Section 1 */}
					<div className="grid grid-cols-12 text-center md:text-left py-40">
						<div className="col-span-full md:col-span-5 px-20 xl:px-40 order-last md:order-first mt-60">
							<h2 className="h1">{about.section[0].headline}</h2>
							<h3 className="pt-15 md:pt-30 text-grey">
								{about.section[0].subheadline}
							</h3>
						</div>

						<div className="col-span-full md:col-span-7 md:p-40 xl:p-60 mt-60 order-first md:order-last">
							<Slideshow slides={about.section[0].images} speed={500} />
						</div>
					</div>

					{/* Section 2 */}
					<div className="grid md:grid-cols-12 text-center md:text-left py-40">
						<div className="col-span-full md:col-span-6 lg:col-span-7 md:p-10 xl:p-60">
							<Image asset={about.section[1].images[0]} />
						</div>

						<div className="col-span-full md:col-span-6 lg:col-span-5 px-30 mt-60 md:mt-100 xl:mt-200">
							<h2 className="h1">{about.section[1].headline}</h2>
							<h3 className="pt-15 md:pt-30 text-grey">
								{about.section[1].subheadline}
							</h3>
						</div>
					</div>

					{/* Section 3 */}
					<div className="grid grid-cols-12 text-center md:text-left py-40">
						<div className="col-span-full md:col-span-6 lg:col-span-7 px-30 xl:px-80 md:mt-80 xl:mt-120 order-last md:order-first mt-60">
							<h2 className="h1">{about.section[2].headline}</h2>
							<h3 className="pt-15 md:pt-30 text-grey">
								{about.section[2].subheadline}
							</h3>
						</div>

						<div className="col-span-full md:col-span-6 lg:col-span-5 md:p-30 mt-60 order-first md:order-last">
							<Slideshow slides={about.section[2].images} speed={500} />
						</div>
					</div>
				</section>

				{/* What We Do / What We Dont */}
				<section className="py-80 xl:py-120">
					{/* What We Do */}
					<div>
						<h2 className="leading-loose">
							<span className="font-medium text-green underline">
								What we do
							</span>
							<Emoji
								label="What we do"
								emoji="&#x1F64B;&#x200D;&#x2640;&#xFE0F;"
								space="20"
							/>
							Play fair
							<Emoji label="Play fair" emoji="&#x1F91D;" space="20" />
							Take pride in our work
							<Emoji label="Take pride" emoji="&#x1F451;" space="20" />
							Make healthy profits
							<Emoji label="Make healthy profits" emoji="&#x2705;" space="20" />
							Love dogs
							<Emoji label="Love dogs" emoji="&#x1F415;" space="20" />
							Take ownership of projects
							<Emoji label="Take ownership" emoji="&#x1F693;" space="20" />
							Get our hands dirty
							<Emoji label="Get our hands dirty" emoji="&#x1F335;" space="20" />
							Build for the long-term
							<Emoji label="Long-Term" emoji="&#x1F4C6;" space="20" />
							Obsess over details
							<Emoji label="Obsess over details" emoji="&#x1F52C;" space="20" />
							Wolf down tubs of ice-cream
							<Emoji label="Ice-Cream" emoji="&#x1F366;" space="15" />
							Trust our gut
							<Emoji label="Trust our gut" emoji="&#x1FAC0;" space="15" />
						</h2>
					</div>

					{/* What We Dont */}
					<div className="pt-60 lg:pt-80">
						<h2 className="leading-loose">
							<span className="font-medium text-red underline">
								What we don't
							</span>
							<Emoji
								label="What we don’t"
								emoji="&#x1F645;&#x200D;&#x2642;&#xFE0F;"
								space="20"
							/>
							Outsource work
							<Emoji label="Outsource work" emoji="&#x26D4;" space="20" />
							Pitch
							<Emoji label="Pitch" emoji="&#x1F99A;" space="20" />
							Take shortcuts (Except
							<Emoji label="Command" emoji="&#x2318;" space="15" />
							+ Space)
							<Emoji label="Take shortcuts" emoji="&#x1F3C7;" space="20" />
							Instagram Diarrhea
							<Emoji label="Instagram" emoji="&#x1F3BA;" space="20" />
							Vie for awards
							<Emoji label="Vie for awards" emoji="&#x1F3C6;" space="20" />
							Work crazy hours
							<Emoji label="Work crazy hours" emoji="&#x1F987;" space="20" />
							Take directions from clients
							<Emoji label="Clients" emoji="&#x26A0;&#xFE0F;" space="20" />
							Performative Activism
							<Emoji label="Activism" emoji="&#x1F921;" space="20" />
							Negotiate on fees
							<Emoji label="Negotiate" emoji="&#x1F93A;" space="20" />
							Bullshit
							<Emoji label="Bullshit" emoji="&#x1F4A9;" space="20" />
						</h2>
					</div>
				</section>

				{/* Parallax Image Animation */}
				<section className="my-20 sm:my-60 xl:my-120 overflow-hidden lg:h-400">
					<motion.div
						className="transform-gpu"
						style={{
							translateY: isDesktop ? imageDesktop : 'none',
						}}
					>
						<Image asset={about.parallax} />
					</motion.div>
				</section>

				{/* Industries Hover Animation */}
				<section className="py-60 lg:py-80">
					<div className="grid gtid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-30">
						{about.industries.map(({ id, industry, gif }) => (
							<Link
								className="relative group"
								key={`industry_${id}`}
								to={`/portfolio/industry/?ind=${industry.slug}`}
								onMouseMove={isDesktop ? moveImage : undefined}
							>
								<div className="relative z-30">
									<h2 className="py-20 lg:py-60 group-hover:text-blue group-hover:underline">
										{industry.title}
									</h2>
								</div>

								<div
									className="top-0 left-0 absolute hidden lg:block w-full invisible group-hover:visible max-w-industry transform-gpu"
									style={{
										transform: `translate3d(${position.x}px ,${position.y}px, 0px )`,
									}}
								>
									<Image asset={gif} />
								</div>
							</Link>
						))}
					</div>
				</section>

				{/* Team Cards with Details Modal */}
				{/* <section className="py-40 lg:py-80">
					<h2 className="h1 w-10/12">{about.team_headline}</h2>

					<div className="grid grid-cols-1 lg:grid-cols-3 auto-rows-fr gap-20 lg:gap-30 py-40 lg:py-120">
						{drafts.map(member => (
							<Link
								key={`team_${member.id}`}
								to={`/about/${member.slug}`}
								state={{
									modal: true,
								}}
							>
								<Team team={member} />
							</Link>
						))}
					</div>
				</section> */}
			</div>
		</Layout>
	)
}

AboutPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			aboutPage: PropTypes.shape({
				headline: PropTypes.string.isRequired,
				slideshow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				team_headline: PropTypes.string.isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

// Team Card with Hue Hover Effect
const Team = ({ team }) => {
	return (
		<HueCard>
			<div className="flex flex-col h-full justify-between">
				<h3 className="h1 group-hover:text-blue group-hover:underline">
					{team.name}
				</h3>
				<h3 className="text-grey pt-60 lg:pt-100">{team.role}</h3>
			</div>
		</HueCard>
	)
}

Team.propTypes = {
	team: PropTypes.shape({
		name: PropTypes.string.isRequired,
		role: PropTypes.string.isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			teams(publicationState: PREVIEW, sort: "name:asc") {
				id
				slug
				name
				role
			}
			aboutPage {
				headline
				slideshow {
					id
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 1290
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
				section {
					headline
					subheadline
					images {
						id
						url
						ext
						height
						width
						name
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: FULL_WIDTH
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				parallax {
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 1290
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
				industries {
					id
					industry {
						slug
						title
					}
					gif {
						url
						ext
						height
						width
						name
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 180
									height: 135
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				team_headline
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default AboutPage
